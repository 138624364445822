import React from 'react';
import Home from '../components/credittracker/Home';
import About from '../components/credittracker/About';
import Services from '../components/credittracker/Services';
import PricingPlan from '../components/credittracker/PricingPlan';
import Blogs from '../components/credittracker/Blogs';
import Contact from '../components/credittracker/Contact';
  
import Footer from '../components/credittracker/Footer';
import Brand from '../components/credittracker/Brand';
import Testimonials from '../components/credittracker/Testimonials';
import FAQ from '../components/credittracker/Faq';
import CTA from '../components/credittracker/CTA';
import Works from '../components/credittracker/Works';


function CreditTracker() {
  return (
    <div className="App">
     
      {/* <Home /> */}
      
      <Services />
      <Works/>
      {/* <Brand/> */}
      <About />
      {/* <Testimonials /> */}
      <CTA />
      <FAQ />
      {/* <PricingPlan /> */}
      {/* <Blogs /> */}
      
      {/* <Contact /> */}
      {/* <Footer /> */}
    </div>
    
  );
}

export default CreditTracker;
