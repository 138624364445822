import React from 'react';
import { motion } from 'framer-motion';

const CTA = () => {
  return (
    <section
      id="cta"
      className="flex items-center justify-center bg-gradient-to-r from-green-900 to-green-100 text-white py-10"
    >
      <div className="container mx-auto text-center px-4">
        <motion.h2
          className="text-2xl md:text-3xl font-bold mb-4"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          Take control of your finances and make each dollar work smarter for you.
        </motion.h2>
        <motion.p
          className="text-md md:text-lg mb-6"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          Join now to access personalized budgeting tools, spending insights, and interactive simulations designed to keep your financial health on track.
        </motion.p>
        <a href="/#contact" target="_blank" rel="noopener noreferrer"> 
          <motion.button
            className="bg-white text-green-800 font-semibold py-3 px-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl mb-4 mr-4"
            whileHover={{ scale: 1.1, rotate: 2 }}
            whileTap={{ scale: 0.95 }}
            transition={{ type: "spring", stiffness: 300 }}
          >
            Buy Now ($9.99)
          </motion.button>
        </a>
        <a href="#demo" > 
          <motion.button
            className="bg-white text-green-800 font-semibold py-3 px-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl ml-4"
            whileHover={{ scale: 1.1, rotate: 2 }}
            whileTap={{ scale: 0.95 }}
            transition={{ type: "spring", stiffness: 300 }}
          >
            Get Started – Free Trial Available!
          </motion.button>
        </a>
      </div>
    </section>
  );
};

export default CTA;