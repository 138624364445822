import React, { useState, useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import './Services.css';
import { LinearProgress, Box } from '@mui/material';

const Services = () => {
  const [budget, setBudget] = useState(0);
  const [expenses, setExpenses] = useState({
    food: 0,
    entertainment: 0,
    bills: 0
  });
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  const updateProgress = (category, value) => {
    setExpenses(prev => ({
      ...prev,
      [category]: Number(value)
    }));
  };

  const simulate = () => {
    const totalSpending = Object.values(expenses).reduce((a, b) => a + b, 0);
    
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    const ctx = chartRef.current.getContext('2d');
    chartInstance.current = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ['Food', 'Entertainment', 'Bills'],
        datasets: [{
          label: 'Spending in Each Category',
          data: Object.values(expenses),
          backgroundColor: ['#ff6384', '#36a2eb', '#ffce56'],
          borderWidth: 1,
          borderColor: '#ffffff30'
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: { 
            beginAtZero: true,
            grid: {
              color: '#ffffff20'
            },
            ticks: {
              color: '#fff'
            }
          },
          x: {
            grid: {
              color: '#ffffff20'
            },
            ticks: {
              color: '#fff'
            }
          }
        },
        plugins: {
          title: {
            display: true,
            text: 'Budget vs Spending',
            color: '#fff',
            font: {
              size: 16
            }
          },
          legend: {
            labels: {
              color: '#fff'
            }
          }
        }
      }
    });
  };

  const getProgressColor = (value, budget) => {
    const percentage = (value / budget) * 100;
    if (budget === 0) return '#e0e0e0';
    if (percentage > 100) return '#ff4444';
    if (percentage > 75) return '#ffa726';
    if (percentage > 50) return '#42a5f5';
    return '#66bb6a';
  };

  return (
    <div className="services-container" id='demo'>
      <div className="info-section">
        <h1 className="info-title">
          <span className="highlight">CreditTracker</span>
        </h1>
        <div className="info-card">
          <img 
            src="/images/tracker.jpg" 
            alt="Budget Planning" 
            className="info-image"
          />
          <div className="info-description">
            <h3><span className="accent">Take Control</span> of Your Finances</h3>
            <p>
              Our interactive budget simulator powered by Nvidia Riva, helps you visualize your spending across
              different categories. Set your monthly budget, adjust your expenses, and
              get instant feedback on your financial decisions.
            </p>
            <div className="features-container">
              <h4>Key Features</h4>
              <ul className="features-list">
                <li>
                  <span className="feature-icon">📊</span>
                  Real-time budget tracking
                </li>
                <li>
                  <span className="feature-icon">📈</span>
                  Visual spending breakdown
                </li>
                <li>
                  <span className="feature-icon">⚡</span>
                  Instant alerts for overspending
                </li>
                <li>
                  <span className="feature-icon">🎯</span>
                  Reward system for staying on budget
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="budget-simulator bg-green-100">
        <div className="budget-input-container">
          <label className="budget-label">
            <h2>💰 Set Your Monthly Budget Target!</h2>
            <div className="budget-input-wrapper">
              <span className="currency-symbol">$</span>
              <input 
                type="number" 
                value={budget}
                onChange={(e) => setBudget(Number(e.target.value))}
                className="budget-input"
                placeholder="Enter your target budget..."
              />
            </div>
            <p className="budget-subtitle">Let's make your money work smarter! 🎯</p>
          </label>
        </div>

        <div className="categories">
          {Object.entries(expenses).map(([category, value]) => {
            const percentage = budget > 0 ? (value / budget) * 100 : 0;
            const isOverBudget = percentage > 100;

            return (
              <div key={category} className={`category ${isOverBudget ? 'over-budget' : ''}`}>
                <div className="category-header">
                  <label className="category-label">
                    {category.charAt(0).toUpperCase() + category.slice(1)}: ${value}
                  </label>
                  <span className="percentage-label">
                    {percentage.toFixed(1)}%
                  </span>
                </div>
                
                <div className="slider-container">
                  <input
                    type="range"
                    min="0"
                    max="1000"
                    step="10"
                    value={value}
                    onChange={(e) => updateProgress(category, e.target.value)}
                    className="slider"
                  />
                </div>

                <Box sx={{ width: '100%', mt: 2 }}>
                  <LinearProgress 
                    variant="determinate" 
                    value={Math.min(percentage, 100)}
                    sx={{
                      height: 10,
                      borderRadius: 5,
                      backgroundColor: '#e0e0e0',
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: percentage > 100 ? '#ff4444' : 
                                       percentage > 75 ? '#ffa726' : 
                                       percentage > 50 ? '#42a5f5' : '#66bb6a'
                      }
                    }}
                  />
                </Box>
              </div>
            );
          })}
        </div>

        <button onClick={simulate} className="simulate-button">
          Simulate
        </button>

        {Object.values(expenses).reduce((a, b) => a + b, 0) > budget && (
          <div className="alerts alert-shake">
            <p>Alert: You are over budget by ${(Object.values(expenses).reduce((a, b) => a + b, 0) - budget).toFixed(2)}!</p>
          </div>
        )}

        {Object.values(expenses).reduce((a, b) => a + b, 0) <= budget && budget > 0 && (
          <div className="rewards">
            <p>Congrats! You've stayed within your budget and earned 100 reward points!</p>
          </div>
        )}

        <div className="chart-container">
          <canvas ref={chartRef} />
        </div>
      </div>
    </div>
  );
};

export default Services;
