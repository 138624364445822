import React from 'react';
import { motion } from 'framer-motion';

const About = () => {
  const testimonials = [
    {
      name: "John Doe",
      role: "Small Business Owner",
      text: "Credit Online Finance's AI-powered solutions transformed how I manage my business finances. The personalized recommendations and automated processes saved me countless hours.",
      image: "/images/testimonials/1.png"
    },
    {
      name: "Sarah Johnson",
      role: "Entrepreneur",
      text: "The innovative approach to financial planning helped me secure the funding I needed. Their AI technology made the entire process seamless and efficient.",
      image: "/images/testimonials/2.png"
    },
    {
      name: "Michael Chen",
      role: "Financial Advisor",
      text: "As a financial advisor, I'm impressed by the accuracy and reliability of their AI-driven insights. It's revolutionizing how we approach financial planning.",
      image: "/images/testimonials/3.png"
    }
  ];

  return (
    <section
      id="testimonials"
      className="min-h-screen py-20 bg-gradient-to-b from-gray-50 to-white flex items-center"
    >
      <div className="container mx-auto px-4 relative z-10">
        {/* Section Title */}
        <motion.div
          className="text-center mb-16"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <h2 className="text-4xl font-bold text-gray-800 mb-4">What Our Clients Say
            <span className="block h-1 w-24 bg-darkGreen mt-4 mx-auto"></span>
          </h2>
          <p className="text-gray-600 mt-4 max-w-2xl mx-auto">
            Discover what our clients have to say about their experience with our AI-powered financial solutions.
          </p>
        </motion.div>

        {/* Testimonials Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-7xl mx-auto">
          {testimonials.map((testimonial, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.2 }}
            >
              <div className="bg-white rounded-xl shadow-2xl p-8 transform hover:scale-105 transition-transform duration-300">
                <div className="flex flex-col items-center">
                  <div className="relative mb-6">
                    <img
                      src={testimonial.image}
                      alt={testimonial.name}
                      className="w-20 h-20 rounded-full object-cover border-4 border-green-100"
                    />
                    <div className="absolute -bottom-2 -right-2 w-8 h-8 bg-green-800 rounded-full flex items-center justify-center">
                      <svg className="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10.394 2.08a1 1 0 00-.788 0l-7 3a1 1 0 000 1.84L5.25 8.051a.999.999 0 01.356-.257l4-1.714a1 1 0 11.788 1.838L7.667 9.088l1.94.831a1 1 0 00.787 0l7-3a1 1 0 000-1.838l-7-3zM3.31 9.397L5 10.12v4.102a8.969 8.969 0 00-1.05-.174 1 1 0 01-.89-.89 11.115 11.115 0 01.25-3.762zM9.3 16.573A9.026 9.026 0 007 14.935v-3.957l1.818.78a3 3 0 002.364 0l5.508-2.361a11.026 11.026 0 01.25 3.762 1 1 0 01-.89.89 8.968 8.968 0 00-5.35 2.524 1 1 0 01-1.4 0zM6 18a1 1 0 001-1v-2.065a8.935 8.935 0 00-2-.712V17a1 1 0 001 1z"/>
                      </svg>
                    </div>
                  </div>
                  <p className="text-gray-600 text-lg italic mb-6 text-center leading-relaxed">
                    "{testimonial.text}"
                  </p>
                  <h4 className="text-xl font-semibold text-gray-800">{testimonial.name}</h4>
                  <p className="text-green-800 font-medium">{testimonial.role}</p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default About;
