import React from 'react';
import { FaFacebook, FaTwitter, FaLinkedin, FaYoutube } from 'react-icons/fa'; // Import icons from react-icons

const Footer = () => {
  return (
    <footer className="bg-lowdarkgreen bg-cover bg-center py-8 flex flex-col">
      <div className="container mx-auto flex flex-wrap justify-between px-6 md:px-12 lg:px-28 pt-8">
        {/* First Column: Logo, Description, Social Media Links */}
        <div className="w-full md:w-1/2 lg:w-1/2 text-left flex flex-col mb-8 md:mb-0">
          <img src="/images/footer_logo.svg" alt="Logo" className="mb-4 w-32 md:w-40" />
          <p className="mb-4 text-sm md:text-base pr-10">
            At Credit Online Finance, we’re redefining finance with advanced AI solutions. Our platform empowers businesses and individuals to make smarter financial decisions, streamline operations, and unlock new growth opportunities.
          </p>
          <div className="flex space-x-5 pt-3">
            <a href="https://web.facebook.com/CredOnFinance/" className="text-darkGreen hover:text-lightGreen text-xl md:text-2xl"><FaFacebook /></a>
            <a href="https://x.com/CredOnFinance" className="text-darkGreen hover:text-lightGreen text-xl md:text-2xl"><FaTwitter /></a>
            <a href="https://www.linkedin.com/company/credit-online-finance/" className="text-darkGreen hover:text-lightGreen text-xl md:text-2xl"><FaLinkedin /></a>
            <a href="https://www.youtube.com/@CreditOnlineFinance" className="text-darkGreen hover:text-lightGreen text-xl md:text-2xl"><FaYoutube /></a>
          </div>
        </div>

        {/* Second Column: Useful Links */}
        <div className="w-full md:w-1/4 lg:w-1/4 text-left flex flex-col mb-8 md:mb-0">
          <h3 className="text-lg font-semibold mb-2">Useful Links</h3>
          <ul className="space-y-2 text-sm md:text-base">
            <li><a href="/#home" className="hover:text-gray-400">Home</a></li>
            <li><a href="/#about" className="hover:text-gray-400">About</a></li>
            <li><a href="/#services" className="hover:text-gray-400">Services</a></li>
            <li><a href="/#pricing" className="hover:text-gray-400">Pricing</a></li>
            <li><a href="/#contact" className="hover:text-gray-400">Contact</a></li>
          </ul>
        </div>

        {/* Third Column: Contact Us */}
        <div className="w-full md:w-1/4 lg:w-1/4 text-left flex flex-col space-y-2">
          <h3 className="text-lg font-semibold mb-2">Contact</h3>
          <p className="mb-2 text-sm md:text-base">268 Elvitigala Mawatha, Colombo 00500, Sri Lanka.</p>
          <p className="mb-2 text-sm md:text-base">admin@app.creditonlinefinance.com</p>
          <p className="text-sm md:text-base">+94 76 301 6688</p>
        </div>
      </div>

      {/* Copyright Text */}
      <div className="text-center mt-8">
        <p className="text-xs md:text-sm text-black">
          &copy; {new Date().getFullYear()} <a href="http://creditonlinefinance.com/" className="font-semibold hover:text-gray-500">CreditOnlineFinance.com</a>. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
