import React, { useState } from 'react';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="bg-darkGreen text-white1 py-4 h-24 flex items-center">
      <nav className="container mx-auto flex justify-between items-center px-4">
        {/* Logo */}
        <div>
          <img src="images/Logo_1.svg" alt="FinTech Logo" className="h-16" />
        </div>

        {/* Desktop Navigation */}
        <ul className="hidden md:flex space-x-6">
          <li><a href="/#home" className="hover:text-gray-400">Home</a></li>
          <li><a href="/#about" className="hover:text-gray-400">About</a></li>
          <li><a href="/#services" className="hover:text-gray-400">Services</a></li>
          <li><a href="/#pricing" className="hover:text-gray-400">Pricing</a></li>
          <li><a href="/#contact" className="hover:text-gray-400">Contact</a></li>
        </ul>

        {/* Desktop Buttons */}
        <ul className="hidden md:flex space-x-6">
          <li>
            <a
              href="https://app.creditonlinefinance.com/user/login"
              className="bg-lightGreen hover:bg-white text-white hover:text-darkGreen font-semibold py-2 px-4 rounded-lg"
            >
              Register
            </a>
          </li>
          <li>
            <a
              href="/credittracker"
              className="bg-lightGreen hover:bg-white text-white hover:text-darkGreen font-semibold py-2 px-4 rounded-lg"
            >
              Credit Tracker
            </a>
          </li>
        </ul>

        {/* Hamburger Menu for Mobile */}
        <button
          className="block md:hidden text-white focus:outline-none"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            ></path>
          </svg>
        </button>

        {/* Mobile Navigation */}
        {isMenuOpen && (
          <div className="absolute top-16 left-0 w-full bg-darkGreen shadow-lg z-10 md:hidden">
            <ul className="flex flex-col items-center space-y-4 py-4">
              <li><a href="/#home" className="hover:text-gray-400">Home</a></li>
              <li><a href="/#about" className="hover:text-gray-400">About</a></li>
              <li><a href="/#services" className="hover:text-gray-400">Services</a></li>
              <li><a href="/#pricing" className="hover:text-gray-400">Pricing</a></li>
              <li><a href="/#contact" className="hover:text-gray-400">Contact</a></li>
              <li>
                <a
                  href="https://app.creditonlinefinance.com/user/login"
                  className="bg-lightGreen hover:bg-white text-white hover:text-darkGreen font-semibold py-2 px-4 rounded-lg"
                >
                  Register
                </a>
              </li>
              <li>
                <a
                  href="/credittracker"
                  className="bg-lightGreen hover:bg-white text-white hover:text-darkGreen font-semibold py-2 px-4 rounded-lg"
                >
                  Credit Tracker
                </a>
              </li>
            </ul>
          </div>
        )}
      </nav>
    </header>
  );
};

export default Header;

