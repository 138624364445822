import React from 'react';
import { motion } from 'framer-motion';

const steps = [
  {
    title: "Sign Up",
    description: "Create your account in just a few minutes and start your journey towards better financial health.",
    icon: "📝", // You can replace this with an actual icon or image
  },
  {
    title: "Connect Your Accounts",
    description: "Link your bank accounts and financial services to get a complete view of your finances.",
    icon: "🔗",
  },
  {
    title: "Set Your Goals",
    description: "Define your financial goals and let our AI-powered tools help you achieve them.",
    icon: "🎯",
  },
  {
    title: "Track Your Progress",
    description: "Monitor your spending, savings, and progress towards your goals with real-time insights.",
    icon: "📊",
  },
];

const HowItWorks = () => {
  return (
    <section
      id="how-it-works"
      className="py-20 bg-green-100"
    >
      <div className="container mx-auto text-center px-4">
        <h2 className="text-3xl font-bold mb-8">How It Works</h2>
        <p className="text-lg mb-12">
          Follow these simple steps to take control of your finances.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {steps.map((step, index) => (
            <motion.div
              key={index}
              className="bg-white rounded-lg shadow-lg p-6 transform transition-transform hover:scale-105"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
            >
              <div className="text-4xl mb-4">{step.icon}</div>
              <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
              <p className="text-gray-600">{step.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;