import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
import PricingPlan from './components/PricingPlan';
import Blogs from './components/Blogs';
import Contact from './components/Contact';
import Header from './components/Header';
import Footer from './components/Footer';
import Brand from './components/Brand';
import Testimonials from './components/Testimonials';
import CreditTracker from './pages/credittracker';





function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={
            <>
              <Home />
              <Services />
              <Brand />
              <About />
              <Testimonials />
              <PricingPlan />
              {/* <Blogs /> */}
              <Contact />
            </>
          } />
          <Route path="/credittracker" element={<CreditTracker />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;  