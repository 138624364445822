import React, { useState } from 'react';
import { motion } from 'framer-motion';

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "What features can I access during the free trial?",
      answer: "Experience the full range of budgeting tools, spending insights, and interactive simulations to see how they benefit your financial health."
    },
    {
      question: "How do spending alerts and rewards work?",
      answer: "Our AI monitors your spending habits and sends real-time alerts to help you stay on budget, with rewards for meeting your financial goals."
    },
    {
      question: "Is my financial data secure on this platform?",
      answer: "Yes, we use advanced encryption and security protocols to ensure your data is protected."
    },
    {
      question: "Can I customize the spending categories?",
      answer: "Absolutely! Tailor the categories to match your specific financial needs and lifestyle."
    },
    {
      question: "What happens after my free trial ends?",
      answer: "You can choose a subscription plan that suits your needs or continue with a basic, limited version."
    }
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section
      id="faq"
      className="min-h-screen py-20 bg-gradient-to-b from-gray-50 to-white flex items-center"
    >
      <div className="container mx-auto px-4 relative z-10">
        {/* Section Title */}
        <motion.div
          className="text-center mb-16"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <h2 className="text-4xl font-bold text-gray-800 mb-4">Frequently Asked Questions
            <span className="block h-1 w-24 bg-green-800 mt-4 mx-auto"></span>
          </h2>
        </motion.div>

        {/* FAQ List */}
        <div className="space-y-4 max-w-2xl mx-auto">
          {faqs.map((faq, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
              className="bg-white rounded-xl shadow-lg overflow-hidden"
            >
              <div 
                className="flex justify-between items-center p-4 cursor-pointer bg-green-100 hover:bg-gray-100"
                onClick={() => toggleFAQ(index)}
              >
                <h3 className="text-lg font-semibold">{faq.question}</h3>
                <span className="text-gray-500">{activeIndex === index ? '-' : '+'}</span>
              </div>
              {activeIndex === index && (
                <div className="p-4 border-t">
                  <p className="text-gray-600">{faq.answer}</p>
                </div>
              )}
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;